<template>
  <article>
    <section id="album" class="album">
      <div class="albumImage">
        <img
          :src="getImagePath(this.album.name)"
          :alt="`To Dust - ${this.album.name}`"
          :title="`To Dust - ${this.album.name}`"
        />
      </div>
      <div class="songs">
        <div class="songTitles">
          <ol type="I">
            <li
              v-for="tune in album.tunes"
              :key="tune.name"
              v-bind:class="{ lyric: tune.lyricId != null }"
              v-on:click="showLyrics(tune.name)"
            >
              {{ tune.name }}
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section class="albumLyrics" v-if="lyricsVisible">
      <hr />
      <LyricsContainer
        v-bind:lyrics-title="activeLyricsTitle"
        v-bind:lyrics="activeLyrics"
        v-on:closeLyrics="setLyricsVisible(false)"
      />
    </section>
  </article>
</template>

<script>
import LyricsContainer from './LyricsContainer.vue';

export default {
  name: 'Album',
  components: {
    LyricsContainer,
  },
  props: ['album'],
  data() {
    return {
      lyricsVisible: false,
      activeLyricsTitle: '',
      activeLyrics: '',
    };
  },
  methods: {
    getImagePath(albumName) {
      const albumNameLowerCase = albumName.toLowerCase();
      const albumNameFormatted = albumNameLowerCase.replace(/ /g, '-');
      return require(`../../../assets/images/album-covers/${albumNameFormatted}.jpg`);
    },
    showLyrics(lyricName) {
      this.getLyrics(lyricName);
      this.setLyricsVisible(true);
    },
    async getLyrics(lyricName) {
      const response = await this.$http.get(
        `${this.$store.getters.toDustApiBaseUrl}/Lyrics/${lyricName}`,
      );

      this.activeLyricsTitle = response.data.name;
      this.activeLyrics = response.data.text;
    },
    setLyricsVisible(value) {
      this.lyricsVisible = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.album {
  display: flex;
  flex-direction: column;
  width: 240px;
  margin: auto;
}

.albumImage {
  margin: 5px;
  max-width: 300px;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
  }
}

.songTitles {
  text-align: center;
}

.lyric {
  cursor: pointer;
  text-decoration-line: underline;
}

@media (min-width: 750px) {
  .album {
    flex-direction: row;
    width: 550px;
  }

  .songTitles {
    text-align: left;
  }

  .songs {
    padding: 5px 20px 5px 40px;
    font-size: 1.2em;
  }
}
</style>
