<template>
  <section id="secMusic">
    <h2 id="hMusic">{{ $t("headers.music") }}</h2>
    <div id="digitalDistributionStreaming" class="readFriendlyLayout">
      <p>
        {{ $t("view.music.streaming-text") }}
      </p>

      <IconLinks v-bind:iconLinks="digitalStreamingVendors" />
    </div>
  </section>
</template>

<script>
import IconLinks from '../Common/IconLinks.vue';

export default {
  name: 'MusicStreaming',
  components: {
    IconLinks,
  },
  data() {
    return {
      digitalStreamingVendors: [
        {
          title: 'Youtube',
          url: 'https://www.youtube.com/user/ToDustSweden',
          imageLocation: 'images/icons/digital-distribution-icons/streaming',
          imageType: 'png',
        },
        {
          title: 'Spotify',
          url: 'https://open.spotify.com/artist/58vq9YegKeQHRE2QuAv8VJ',
          imageLocation: 'images/icons/digital-distribution-icons/streaming',
          imageType: 'png',
        },
        {
          title: 'iTunes',
          url: 'https://itunes.apple.com/us/artist/554232439',
          imageLocation: 'images/icons/digital-distribution-icons/streaming',
          imageType: 'png',
        },
        {
          title: 'Tidal',
          url: 'https://tidal.com/browse/artist/4703126',
          imageLocation: 'images/icons/digital-distribution-icons/streaming',
          imageType: 'png',
        },
        {
          title: 'Deezer',
          url: 'https://www.deezer.com/en/artist/3809841',
          imageLocation: 'images/icons/digital-distribution-icons/streaming',
          imageType: 'png',
        },
      ],
    };
  },
  methods: {
    getImagePath(streamingVendor) {
      return require(`../../assets/images/icons/digital-distribution-icons/streaming/streaming_${streamingVendor.toLowerCase()}_m.png`);
    },
  },
};
</script>

<style scoped lang="scss"></style>
