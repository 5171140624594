<template>
  <div class="albumController">
    <a class="albumPrev" v-on:click="changeAlbumIndex(-1)">&#10094;</a>

    <a
      class="albumIcon"
      v-for="(album, index) in albums"
      :key="index"
      v-on:click="setAlbumIndex(index)"
      v-bind:class="{ albumIconActive: isActive(index) }"
    >
      <img
        :src="getImagePath(album.name)"
        :alt="`To Dust - ${album.name}`"
        :title="`To Dust - ${album.name}`"
      />
    </a>
    <a class="albumNext" v-on:click="changeAlbumIndex(1)">&#10095;</a>
  </div>
</template>

<script>
export default {
  name: 'AlbumController',
  props: ['albums', 'currentAlbumIndex'],
  methods: {
    getImagePath(albumName) {
      const albumNameLowerCase = albumName.toLowerCase();
      const albumNameFormatted = albumNameLowerCase.replace(/ /g, '-');
      return require(`../../../assets/images/album-covers/${albumNameFormatted}.jpg`);
    },
    setAlbumIndex(index) {
      this.$emit('setAlbumIndex', index);
    },
    changeAlbumIndex(change) {
      this.$emit('changeAlbumIndex', change);
    },
    isActive(index) {
      return index === this.currentAlbumIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
.albumController {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80px;
  padding: 5px 0 5px 0;
}

.albumPrev,
.albumNext {
  font-size: 2em;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.albumIcon {
  max-width: 80px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 5px;
  margin-bottom: 5px;
  filter: brightness(75%);

  &:hover {
    border: 1px solid #c4c1b4;
  }

  img {
    max-width: 90%;
    max-height: 90%;
    margin: 5px;
  }
}

.albumIconActive {
  filter: brightness(100%);
}
</style>
