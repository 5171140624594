<template>
  <section id="secVideos">
    <h2 id="hVideos">{{ $t("headers.videos") }}</h2>
    <div id="videos" class="flexContainerRow videoContainer">
      <div
        class="video"
        v-for="videolink in videolinks.slice().reverse()"
        :key="videolink.id"
      >
        <iframe
          width="560"
          height="315"
          :src="videolink.url"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VideoStreaming',
  data() {
    return {
      videolinks: [
        {
          id: 1,
          title: 'Uncrowned music video',
          url: 'https://www.youtube.com/embed/hWfdNku4bM0',
        },
        {
          id: 2,
          title: 'Kulturnatten 2017-09-16',
          url: 'https://www.youtube.com/embed/6wtoeRm5SAI',
        },
        {
          id: 3,
          title: 'I Commodity live @Rockbåten',
          url: 'https://www.youtube.com/embed/lIVxEFdJ8fE',
        },
        {
          id: 4,
          title: 'Wishes to dust',
          url: 'https://www.youtube.com/embed/D5iLLChYLBA',
        },
        {
          id: 5,
          title: 'Mute studio video',
          url: 'https://www.youtube.com/embed/TOxxr1IIFss',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.flexContainerRow {
  display: flex;
  flex-direction: row;
}
.videoContainer {
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
}

.video {
  flex-grow: 1;
  margin: 5px;

  iframe {
    width: 100%;
  }
}
</style>
