<template>
  <section id="secAlbums" class="lyricText">
    <h2>{{ $t("headers.albums-and-lyrics") }}</h2>
    <p>{{ $t("view.music.lyrics-text") }}</p>
    <div class="albumContainer readFriendlyLayout" v-if="albums.length > 0">
      <AlbumController
        v-bind:albums="albums"
        v-bind:current-album-index="currentAlbumIndex"
        v-on:setAlbumIndex="setAlbumIndex($event)"
        v-on:changeAlbumIndex="changeAlbumIndex($event)"
      />
      <hr />
      <Album v-bind:album="currentAlbum" />
    </div>
  </section>
</template>

<script>
import AlbumController from './AlbumController.vue';
import Album from './Album.vue';

export default {
  name: 'Albums',
  components: {
    AlbumController,
    Album,
  },
  data() {
    return {
      albums: [],
      currentAlbumIndex: 0,
      collectionTypesToGet: ['album'],
    };
  },
  computed: {
    currentAlbum() {
      return this.albums[this.currentAlbumIndex];
    },
  },
  methods: {
    setAlbumIndex(index) {
      this.currentAlbumIndex = index;
    },
    changeAlbumIndex(change) {
      this.currentAlbumIndex += change;
      if (this.currentAlbumIndex >= this.albums.length) {
        this.currentAlbumIndex = 0;
      } else if (this.currentAlbumIndex < 0) {
        this.currentAlbumIndex = this.albums.length - 1;
      }
    },
    async getAlbums() {
      const { data } = await this.$http
        .post(
          `${this.$store.getters.toDustApiBaseUrl}/tunecollections/types`,
          this.collectionTypesToGet,
        );
      this.albums = data;
    },
  },
  mounted() {
    this.getAlbums();
  },
};
</script>

<style scoped>
.lyricText {
  text-align: center;
}

.albumContainer {
  text-align: center;
  overflow: hidden;
  border: 1px solid #646154;
  border-radius: 10px;
  padding: 5px;
}
</style>
