<template>
  <div>
    <MusicStreaming />
    <Albums />
    <VideoStreaming />
  </div>
</template>

<script>
import MusicStreaming from '../components/Media/MusicStreaming.vue';
import Albums from '../components/Media/Albums/Albums.vue';
import VideoStreaming from '../components/Media/VideoStreaming.vue';

export default {
  name: 'Music',
  components: {
    MusicStreaming,
    Albums,
    VideoStreaming,
  },
};
</script>
